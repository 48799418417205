<template>
  <div>
    <div>管理文生图、图生图的流程分组</div>
    <a-row :gutter="[15, 10]" style="margin: 5px 0">
      <a-col :span="5">
        <a-input-search
          placeholder="搜索分组"
          allowClear
          v-model="searchForm.groupName"
          @search="getDataList(true)"
        ></a-input-search>
      </a-col>
      <a-col :span="5">
        <a-select
          v-model="searchForm.actor"
          placeholder="操作人"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="(item, index) in creatorList" :key="index" :value="item">{{ item }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      row-key="id"
      :pagination="false"
      :loading="tableLoading"
      :data-source="dataList"
      @change="onTableChange"
    >
      <div slot="taskType" slot-scope="text, record">
        {{ getSubjectType(record.taskType) }}
      </div>
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="handleOpen(record)">编辑</a-button>
          <a-popconfirm
            placement="topLeft"
            title="确定删除该分组？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDel(record.id)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <a-modal
      width="600px"
      title="添加"
      :visible="showAdd"
      :confirmLoading="addLoading"
      @close="handleClose"
      @cancel="handleClose"
      @ok="handleAddConfirm"
    >
      <a-form-model
        style="width: 100%"
        :model="addParams"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :rules="rules"
        ref="form"
      >
        <a-form-model-item label="流程分类" prop="taskType">
          <a-select placeholder="流程分类" v-model="addParams.taskType">
            <a-select-option v-for="item in processClassList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分组名" prop="groupName">
          <a-input placeholder="分组名" v-model.trim="addParams.groupName"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import api from '../api.js';

export default {
  components: {},
  data() {
    const processClassList = [
      { label: '文生图', value: 'TEXT2IMAGE_CAR' },
      { label: '图生图-换背景', value: 'CAR_REPLACE_BACKGROUND' },
      { label: '图生图-换模特', value: 'REPLACE_MODEL' },
      // { label: '车辆信息还原', value: 'VEHICLE_INFORMATION_RESTORATION' },
      // { label: 'Ai改图', value: 'CAR_IMG_BY_IMG' },
    ];
    const rules = {
      groupName: [{ required: true, message: '请输入分组名', trigger: 'blur' }],
      taskType: [{ required: true, message: '请选择分类', trigger: 'change' }],
    };
    return {
      columns: [
        {
          title: '流程分类',
          key: 'taskType',
          scopedSlots: { customRender: 'taskType' },
        },
        {
          title: '分组',
          dataIndex: 'groupName',
          key: 'groupName',
        },
        {
          title: '操作人',
          dataIndex: 'actor',
          key: 'actor',
        },
        {
          title: '更新时间',
          dataIndex: 'mtime',
          key: 'mtime',
          sorter: true,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      rules,
      processClassList,
      searchForm: {},
      creatorList: [],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      mtimeDesc: true,
      showAdd: false,
      addLoading: false,
      addParams: {
        id: undefined,
        taskType: undefined,
        groupName: undefined,
      },
    };
  },
  created() {
    this.getDataList(true);
    this.getCreatorList();
  },
  methods: {
    getCreatorList() {
      api.getAiImageSubjectGroupOperatorList().then((res) => {
        if (res.code == 200) {
          this.creatorList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDataList(init) {
      if (init) this.pagination.current = 1;
      const params = {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        mtimeDesc: this.mtimeDesc,
      };
      this.tableLoading = true;
      api
        .getAiImageSubjectGroupList(params)
        .then((res) => {
          if (res.code == 200) {
            this.dataList = res.data.list;
            this.pagination.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleDel(id) {
      api.deleteAiImageSubjectGroup(id).then(({ code, message }) => {
        if (code == 200) {
          this.$message.success('删除成功');
          if (this.dataList.length == 1 && this.pagination.current > 1) {
            this.pagination.current--;
          }
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      });
    },
    handleOpen(data) {
      if (data) {
        const { id, taskType, groupName } = data;
        Object.assign(this.addParams, { id, taskType, groupName });
      }else{
        this.addParams.taskType = 'TEXT2IMAGE_CAR'
      }
      this.showAdd = true;
    },
    handleAddConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addLoading = true;
          api
            .updateAiImageSubjectGroup(this.addParams)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success('操作成功');
                this.handleClose();
                this.getDataList(true);
              } else {
                this.$message.error(res.msg);
              }
            })
            .finally(() => {
              this.addLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.showAdd = false;
      this.addParams = {
        id: undefined,
        taskType: undefined,
        groupName: undefined,
      };
      this.$refs.form.clearValidate();
    },
    onTableChange(pagination, filters, sorter) {
      this.mtimeDesc = sorter.order == 'ascend' ? false : true;
      this.getDataList(true);
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getSubjectType(type) {
      const returnName = this.processClassList.find((item) => item.value === type);
      return returnName ? returnName.label : '-';
    },
  },
};
</script>

<style lang="less" scoped></style>
