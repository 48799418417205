<template>
  <div>
    <a-row :gutter="[15, 10]" style="margin-bottom: 20px">
      <a-col :span="3">
        <a-select
          v-model="searchForm.publishStatus"
          placeholder="请选择状态"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in stateList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <a-select
          v-model="searchForm.subjectTypeList"
          placeholder="分类"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in processClassList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <a-select
          v-model="searchForm.actor"
          placeholder="操作人"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="(item, index) in creatorList" :key="index" :value="item">{{ item }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <a-input-search
          v-model.trim="searchForm.subjectTitle"
          placeholder="搜索名称"
          @search="getDataList(true)"
        ></a-input-search>
      </a-col>
      <a-col :span="3">
        <a-select
          v-model="searchForm.subjectGroupIdList"
          placeholder="分组"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{
            item.groupName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="5">
        <a-select
          style="width: 100%"
          mode="multiple"
          :maxTagCount="2"
          class="item-select"
          v-model="searchForm.principalIdList"
          placeholder="适用品牌"
          show-search
          option-filter-prop="children"
          @change="getDataList(true)"
          allowClear
        >
          <a-select-option v-for="item in currentBrandList" :key="item.principalId">{{
            item.principalName
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      row-key="id"
      :pagination="false"
      :loading="tableLoading"
      :data-source="dataList"
      @change="onTableChange"
    >
      <div slot="state" slot-scope="text, record, index">
        <any-switch
          v-model="record.publishStatus"
          :loading="switchLoading"
          :checkedVal="true"
          :unCheckedVal="false"
          @change="(check) => handleSwitch(check, record)"
        >
        </any-switch>
      </div>
      <div slot="renderImage" slot-scope="text, record" class="info-left" @click="openBigImg(record.renderImage)">
        <BaseImg
          :src="`${record.renderImage}&x-oss-process=image/resize,l_120,m_lfit`"
          alt="图片"
          referrerpolicy="no-referrer"
          style="cursor: pointer; object-fit: contain"
        />
      </div>
      <div slot="subjectType" slot-scope="text, record">
        {{ getSubjectType(record.subjectType) }}
      </div>
      <div slot="carSeriesInfoList" slot-scope="text, record">
        <template v-if="record.carSeriesInfoList && record.carSeriesInfoList.length">
          <a-tooltip>
            <template slot="title">
              <a-tag v-for="(c, i) in record.carSeriesInfoList" :key="i" color="blue">
                {{ c.carSeriesName }}
              </a-tag>
            </template>
            <a-tag v-for="(item, index) in record.carSeriesInfoList.slice(0, 2)" :key="index" color="blue">
              {{ item.carSeriesName }}
            </a-tag>
            <a-tag v-show="record.carSeriesInfoList.length > 2" color="blue">
              +{{ record.carSeriesInfoList.length - 2 }}
            </a-tag>
          </a-tooltip>
        </template>
        <template v-else>-</template>
      </div>
      <div slot="principalInfoList" slot-scope="text, record">
        <template v-if="record.principalInfoList && record.principalInfoList.length">
          <a-tooltip>
            <template slot="title">
              <a-tag v-for="(c, i) in record.principalInfoList" :key="i" color="purple">
                {{ c.principalName }}
              </a-tag>
            </template>
            <a-tag v-for="(item, index) in record.principalInfoList.slice(0, 2)" :key="index" color="purple">
              {{ item.principalName }}
            </a-tag>
            <a-tag v-show="record.principalInfoList.length > 2" color="purple">
              +{{ record.principalInfoList.length - 2 }}
            </a-tag>
          </a-tooltip>
        </template>
        <template v-else>-</template>
      </div>

      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="handleEdit(record)">编辑</a-button>
          <a-popconfirm
            :disabled="record.publishStatus == 1"
            placement="topLeft"
            title="确定删除该主题？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDel(record.id)"
          >
            <a-button type="link" :disabled="record.publishStatus == 1">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <!-- 新增/编辑主题 -->
    <a-drawer :title="drawerTitle" placement="right" :width="850" :visible="showDrawer" @close="onCloseDrawer">
      <a-select
        v-model="themeForm.subjectType"
        :disabled="isEdit"
        placeholder="类型"
        style="width: 100%; margin-bottom: 10px"
        @change="onTypeChange"
      >
        <a-select-option
          v-for="item in processClassList"
          :key="item.value"
          :value="item.value"
          :disabled="item.value === 'REPLACE_MODEL'"
          >{{ item.label }}</a-select-option
        >
      </a-select>
      <a-form-model
        ref="themeForm"
        :model="themeForm"
        :rules="themeRules"
        :labelCol="{ xl: 5, xxl: 4 }"
        :wrapperCol="{ span: 18 }"
        style="padding-bottom: 36px"
      >
        <div style="margin: 10 0; font-weight: bold">基本信息</div>
        <a-form-model-item label="效果图" ref="renderImage" prop="renderImage">
          <vodBaseUpload
            :accept-list="'.png,.jpg,.jpeg'"
            :has-file-list="themeImgList"
            :params-data="null"
            :client-id="'bfz-admin'"
            @handleUrl="handleUrl"
            @handlePreview="handlePreviewOpen"
          />
        </a-form-model-item>
        <a-form-model-item label="名称" prop="subjectTitle">
          <a-input v-model.trim="themeForm.subjectTitle" placeholder="请填写名称" />
        </a-form-model-item>
        <a-form-model-item label="适用车系" v-show="themeForm.subjectType == 'TEXT2IMAGE_CAR'">
          <a-select
            style="width: 100%"
            mode="multiple"
            v-model="themeForm.carSeriesInfoList"
            placeholder="适用车系"
            show-search
            option-filter-prop="children"
            :maxTagCount="2"
            allowClear
          >
            <a-select-option v-for="item in carSeriesList" :key="`${item.carSeriesId},${item.carSeriesName}`">{{
              item.carSeriesName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分组" prop="subjectGroupId">
          <a-select v-model="themeForm.subjectGroupId" placeholder="请选择分组" style="width: 100%">
            <a-select-option v-for="item in typeGroupList" :key="item.id" :value="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sortNumber" extra="数字越大越靠前">
          <a-input-number
            style="width: 200px"
            placeholder="排序"
            v-model="themeForm.sortNumber"
            :min="0"
            :max="999999"
          />
        </a-form-model-item>
        <a-form-model-item label="说明">
          <a-textarea
            v-model="themeForm.subjectDesc"
            placeholder="请填写说明"
            :auto-size="{ minRows: 3, maxRows: 7 }"
          />
        </a-form-model-item>
        <template v-if="comfyConfigList && Object.keys(comfyConfigList).length">
          <div style="margin: 10 0; font-weight: bold">ComfyUI字段</div>
          <a-form-model-item
            v-for="(item, key, index) in comfyConfigList"
            :key="index"
            :label="key"
            :extra="filterType(item.type) !== 'obj' ? item.desc : ''"
          >
            <template v-if="filterType(item.type) === 'int'">
              <!-- 特殊处理 seed 下限-1 -->
              <a-input-number
                style="width: 200px"
                v-model="item.default"
                :placeholder="`请填写${key}`"
                :min="key === 'seed' ? -1 : 0"
                :precision="0"
              />
            </template>
            <template v-if="filterType(item.type) === 'float'">
              <a-input-number
                v-model="item.default"
                style="width: 200px"
                :placeholder="`请填写${key}`"
                :min="0"
                :precision="2"
              />
            </template>
            <template v-if="filterType(item.type) === 'str'">
              <!-- 特殊处理 prompt-->
              <a-input v-model="item.default" :placeholder="`请填写${key}`" />
            </template>
            <template v-if="filterType(item.type) === 'bool'">
              <a-switch v-model="item.default" />
            </template>
            <template v-if="filterType(item.type) === 'obj'">
              <div v-show="!item.showAll">
                <a-button type="link" icon="down" @click="item.showAll = !item.showAll">展开</a-button>
              </div>
              <div v-show="item.showAll">
                <a-button type="link" icon="up" @click="item.showAll = !item.showAll">收起</a-button>
                <div style="border: 1px solid #ccc; padding: 0 5px; border-radius: 5px">
                  <div v-for="(_item, _index) in item.field_arr" :key="_index">
                    <a-form-model-item
                      v-for="(__item, __key, __index) in _item"
                      :key="__index"
                      :label="__key"
                      :extra="__item.desc"
                      :labelCol="{ span: 10 }"
                      :wrapperCol="{ span: 14 }"
                    >
                      <template v-if="filterType(__item.type) === 'int'">
                        <a-input-number
                          v-model="__item.default"
                          style="width: 200px"
                          :placeholder="`请填写${__key}`"
                          :min="__key === 'seed' ? -1 : 0"
                          :precision="0"
                        />
                      </template>
                      <template v-if="filterType(__item.type) === 'float'">
                        <a-input-number
                          v-model="__item.default"
                          style="width: 200px"
                          :placeholder="`请填写${__key}`"
                          :min="0"
                          :precision="2"
                        />
                      </template>
                      <template v-if="filterType(__item.type) === 'str'">
                        <a-input v-model="__item.default" :placeholder="`请填写${__key}`" />
                      </template>
                      <template v-if="filterType(__item.type) === 'bool'">
                        <a-switch v-model="__item.default" />
                      </template>
                    </a-form-model-item>
                  </div>
                </div>
              </div>
            </template>
            <span v-show="item.sd_web_option">sd_web_option: {{ item.sd_web_option }}</span>
          </a-form-model-item>
        </template>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button type="primary" :loading="saveLoading" @click="handleSave">确认</a-button>
      </div>
    </a-drawer>

    <!-- 图片预览 -->
    <PreviewModal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
  </div>
</template>

<script>
import vodBaseUpload from '@/components/VodBaseUpload/vodBaseUpload';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import BaseImg from '@/components/BaseImg';
import AnySwitch from '@/components/AnySwitch/AnySwitch.vue';
import _api from '@/api/xhsAgencyApi';
import api from '../api.js';

export default {
  name: 'ThemeTab',
  props: {},
  components: {
    vodBaseUpload,
    CarSeriesSelectInput,
    PreviewModal,
    BaseImg,
    AnySwitch,
  },
  data() {
    const stateList = [
      { label: '已下架', value: 0 },
      { label: '已上架', value: 1 },
    ];
    const processClassList = [
      { label: '文生图', value: 'TEXT2IMAGE_CAR' },
      { label: '图生图-换背景', value: 'CAR_REPLACE_BACKGROUND' },
      { label: '图生图-换模特', value: 'REPLACE_MODEL' },
      // { label: '车辆信息还原', value: 'VEHICLE_INFORMATION_RESTORATION' },
      // { label: 'Ai改图', value: 'CAR_IMG_BY_IMG' },
    ];
    const themeRules = {
      subjectType: [{ required: true, message: '请选择类型', trigger: 'change' }],
      renderImage: [{ required: true, message: '请上传效果图', trigger: 'change' }],
      subjectTitle: [{ required: true, message: '请填写名称', trigger: 'change' }],
      subjectGroupId: [{ required: true, message: '请选择分组', trigger: 'change' }],
      sortNumber: [{ required: true, message: '请填写排序', trigger: 'change' }],
    };
    return {
      themeRules,
      columns: [
        {
          title: '排序',
          dataIndex: 'sortNumber',
          sorter: true,
          //   width: 100,
        },
        {
          title: '上架',
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '效果图',
          width: 100,
          scopedSlots: { customRender: 'renderImage' },
        },
        {
          title: '名称',
          dataIndex: 'subjectTitle',
          key: 'subjectTitle',
        },
        {
          title: '分类',
          scopedSlots: { customRender: 'subjectType' },
        },
        {
          title: '分组',
          customRender(record) {
            return record.subjectGroupName || '-';
          },
        },
        {
          title: '适用车系',
          scopedSlots: { customRender: 'carSeriesInfoList' },
        },
        {
          title: '适用品牌',
          scopedSlots: { customRender: 'principalInfoList' },
        },
        {
          title: '操作人',
          customRender(record) {
            return record.actor || '-';
          },
        },
        {
          title: '更新时间',
          dataIndex: 'mtime',
          sorter: true,
        },
        {
          align: 'center',
          title: '操作',
          //   fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      stateList,
      processClassList,
      searchForm: {
        publishStatus: 1,
        subjectTypeList: 'TEXT2IMAGE_CAR',
      },
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      creatorList: [],
      groupList: [],
      principleList: [],
      sortList: [],
      preview_visible: false,
      preview_src: undefined,
      dataList: [],
      tableLoading: false,
      brandList: [],
      switchLoading: false,
      showDrawer: false,
      saveLoading: false,
      themeForm: {
        subjectGroupId: undefined,
        renderImage: undefined,
      },
      drawerTitle: '添加主题',
      themeImgList: [],
      comfyConfigList: {},
      typeGroupList: [],
      carSeriesList: [],
      isEdit: false,
      currentBrandList: [],
    };
  },
  created() {
    this.getDataList(true);
    this.getBrandList();
    this.getCurrentBrandList();
    this.getGroupList();
    this.getCreatorList();
    this.getSeriesList();
  },
  methods: {
    async getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const params = {
        ...this.searchForm,
        subjectTypeList: this.searchForm.subjectTypeList ? [this.searchForm.subjectTypeList] : undefined,
        subjectGroupIdList: this.searchForm.subjectGroupIdList ? [this.searchForm.subjectGroupIdList] : undefined,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        sortFieldList: this.sortList,
      };
      this.tableLoading = true;
      const { code, data, message } = await api
        .getAiImageSubjectList(params)
        .finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList = data.list;
        this.pagination.total = data.total;
      } else {
        this.$message.error(message);
      }
    },
    handleOpen() {
      this.showDrawer = true;
      this.themeForm.subjectType = 'TEXT2IMAGE_CAR';
      this.themeForm.sortNumber = 0;
      this.themeForm.subjectGroupId = undefined;
      this.onTypeChange('TEXT2IMAGE_CAR');
    },
    async handleEdit(data) {
      this.isEdit = true;
      this.showDrawer = true;
      const { renderImage, id, subjectDesc, subjectTitle, subjectType, sortNumber, subjectGroupId } = data;
      if (renderImage) {
        this.themeImgList = [
          {
            uid: 0,
            name: subjectTitle,
            status: 'done',
            url: renderImage,
            thumbUrl: renderImage,
            editor: true,
          },
        ];
      }
      // const principalInfoList =
      //   (data.principalInfoList && data.principalInfoList.map((v) => v.principalId + ',' + v.principalName)) ||
      //   undefined;
      const principalInfoList = [];
      let carSeriesInfoList =
        (data.carSeriesInfoList && data.carSeriesInfoList.map((v) => v.carSeriesId + ',' + v.carSeriesName)) || [];
      if (subjectType != 'TEXT2IMAGE_CAR') carSeriesInfoList = [];
      this.themeForm = {
        renderImage,
        id,
        subjectDesc,
        subjectTitle,
        subjectType,
        sortNumber,
        principalInfoList,
        carSeriesInfoList,
        subjectGroupId: undefined,
      };
      await this.onTypeChange(this.themeForm.subjectType);
      this.themeForm.subjectGroupId = subjectGroupId;
      const config = await this.getComfyConfigById(data.id);
      if (config && this.comfyConfigList) {
        for (let key in config) {
          const value = config[key];
          if (typeof value === 'object') {
            for (let _key in value) {
              const _value = value[_key];
              if (this.comfyConfigList[key]) {
                this.comfyConfigList[key].field_arr.forEach((_ele) => {
                  if (_ele[_key]) _ele[_key].default = _value;
                });
              }
            }
          } else {
            if (this.comfyConfigList[key]) this.comfyConfigList[key].default = value;
          }
        }
      }
    },
    onCloseDrawer() {
      this.$refs.themeForm.clearValidate();
      this.showDrawer = false;
      this.themeForm = {
        subjectGroupId: undefined,
        renderImage: undefined,
      };
      this.comfyConfigList = {};
      this.themeImgList = [];
      this.isEdit = false;
    },
    getComfyConfigById(id) {
      return new Promise((resolve) => {
        _api
          .getSubjectDetail(id)
          .then(({ code, data }) => {
            if (code == 200) {
              resolve(data.subjectConfigParam);
            } else {
              resolve({});
            }
          })
          .catch(() => {
            resolve({});
          });
      });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    async handleSwitch(check, { id }) {
      this.switchLoading = true;
      const params = {
        id,
        publishStatus: check ? 1 : 0,
      };
      const { code, message } = await api
        .updateAiImageSubjectStatus(params)
        .finally(() => (this.switchLoading = false));
      if (code === 200) {
        // if (!isNaN(this.searchForm.publishStatus)) this.getDataList();
        this.$message.success('操作成功');
      } else {
        this.$message.error(message);
        this.getDataList();
      }
    },
    handleDel(id) {
      _api.deleteSubject(id).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功');
          if (this.dataList.length === 1 && this.pagination.current > 1) {
            this.pagination.current--;
          }
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      });
    },
    onTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? [
            {
              fieldName: sorter.columnKey,
              orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            },
          ]
        : [];
      this.sortList = sort;
      this.getDataList(true);
    },
    openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.preview_visible = true;
      this.preview_src = url;
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    //图片上传
    handleUrl(file) {
      let arr = [];
      let url = '';
      if (file) {
        url = file.xhr;
        arr = [
          {
            uid: 1,
            name: 'theme',
            status: 'done',
            url,
            thumbUrl: url,
            editor: true,
          },
        ];
      }

      this.themeImgList = arr;
      this.themeForm.renderImage = url;
      this.$refs.renderImage.onFieldChange();
    },
    handlePreviewOpen(url) {
      const pUrl = url.split('?size')[0];
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
        },
        images: [pUrl],
      });
    },
    handleSave() {
      this.$refs.themeForm.validate((valid) => {
        if (valid) {
          // 处理品牌字段
          let principalInfoList = [];
          // if (this.themeForm.principalInfoList?.length) {
          //   principalInfoList = this.themeForm.principalInfoList.map((val) => {
          //     const parm = val.split(',');
          //     return {
          //       principalId: parm[0],
          //       principalName: parm[1],
          //     };
          //   });
          // } else {
          //   principalInfoList = [];
          // }

          // 处理车系字段
          let carSeriesInfoList = [];
          if (this.themeForm.carSeriesInfoList?.length) {
            carSeriesInfoList = this.themeForm.carSeriesInfoList.map((val) => {
              const parm = val.split(',');
              return {
                carSeriesId: parm[0],
                carSeriesName: parm[1],
              };
            });
            carSeriesInfoList.forEach((item) => {
              this.carSeriesList.forEach((c) => {
                if (c.carSeriesId == item.carSeriesId) {
                  item.principalId = c.principalId;
                  item.principalName = c.principalName;
                }
              });
            });
          } else {
            carSeriesInfoList = [];
          }
          // 处理comfyUI参数
          let subjectConfigParam = {};
          let comfyConfigList = JSON.parse(JSON.stringify(this.comfyConfigList));
          if (comfyConfigList) {
            Object.keys(comfyConfigList).forEach((key) => {
              if (this.filterType(comfyConfigList[key].type) === 'obj') {
                const value = {};
                comfyConfigList[key].field_arr.forEach((item) => {
                  Object.keys(item).forEach((_key) => {
                    value[_key] = item[_key].default;
                  });
                });
                subjectConfigParam[key] = value;
              } else {
                subjectConfigParam[key] = comfyConfigList[key].default;
              }
            });
          }
          const params = {
            ...this.themeForm,
            carSeriesInfoList,
            principalInfoList,
            subjectConfigParam,
          };
          this.saveLoading = true;
          _api
            .saveSubjectBaseInfo(params)
            .then(({ code, message }) => {
              if (code === 200) {
                this.$message.success('保存成功');
                this.onCloseDrawer();
                this.getDataList();
              } else {
                this.$message.error(message);
              }
            })
            .finally(() => {
              this.saveLoading = false;
            });
          console.log('->', params);
        }
      });
    },
    async onTypeChange(taskType) {
      this.themeForm.subjectGroupId = undefined;
      return new Promise(async (resolve) => {
        this.getTypeGroup(taskType);
        const APINAME =
          taskType === 'TEXT2IMAGE_CAR' ? 'getAiImageSubjectTypeConfigNew' : 'getAiImageSubjectTypeConfig';
        await this.getTypeConfig(taskType, APINAME);
        resolve();
      });
    },
    async getTypeConfig(taskType, APINAME) {
      this.comfyConfigList = {};
      const { code, data, message } = await api[APINAME]({ taskType });
      if (code === 200) {
        if (APINAME === 'getAiImageSubjectTypeConfigNew') {
          if (data.dynamicConfigJson && Object.keys(data.dynamicConfigJson).length) {
            for (let key in data.dynamicConfigJson) {
              data.dynamicConfigJson[key].showAll = false;
              if (this.filterType(data.dynamicConfigJson[key].type) === 'obj')
                data.dynamicConfigJson[key].showAll = true;
            }
            this.comfyConfigList = data.dynamicConfigJson;
          } else {
            this.comfyConfigList = {};
          }
        } else {
          this.comfyConfigList = data && Object.keys(data).length ? data : {};
        }
      } else {
        this.$message.error(message);
      }
    },
    async getTypeGroup(taskType) {
      api.getAiImageSubjectGroupList({ taskType, size: 9999 }).then((res) => {
        if (res.code == 200) {
          this.typeGroupList = res.data.list;
          console.log('this.typeGroupList ->', this.typeGroupList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    filterType(type) {
      return type.split("'")[1];
    },

    async getSeriesList() {
      const { code, data, message } = await _api.getXhsAiImageCarSeriesPromptList({ page: 1, size: 999 });
      if (code === 200) {
        this.carSeriesList = data.list;
        console.log('this.carSeriesList ->', this.carSeriesList);
      } else {
        this.$message.error(message);
      }
    },
    async getCreatorList() {
      const { code, data, message } = await api.getAiImageSubjectOperatorList({ page: 1, size: 999 });
      if (code === 200) {
        this.creatorList = data;
      } else {
        this.$message.error(message);
      }
    },
    async getGroupList() {
      const { code, data, message } = await api.getAiImageSubjectGroupList({ page: 1, size: 999 });
      if (code === 200) {
        this.groupList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getBrandList() {
      const { code, data, message } = await _api.getBrandList();
      if (code === 200) {
        this.brandList = data;
      } else {
        this.$message.error(message);
      }
    },
    async getCurrentBrandList() {
      const { code, data, message } = await api.getAiImageSubjectBrandList();
      if (code === 200) {
        this.currentBrandList = data;
      } else {
        this.$message.error(message);
      }
    },
    getSubjectType(type) {
      const returnName = this.processClassList.find((item) => item.value === type);
      return returnName ? returnName.label : '-';
    },
  },
};
</script>

<style lang="less" scoped>
.info-left {
  width: 60px;
  height: 60px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 3px;
}
</style>
