<template>
  <div>
    <a-tabs v-model="tabKey" size="large">
      <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.label"></a-tab-pane>
      <a-button slot="tabBarExtraContent" type="primary" @click="handleAdd"> 添加 </a-button>
    </a-tabs>
    <ThemeTab ref="ThemeTab" v-if="tabKey === 1" />
    <SeriesInfoTab ref="SeriesInfoTab" v-if="tabKey === 2" />
    <GroupTab ref="GroupTab" v-if="tabKey === 3" />
  </div>
</template>

<script>
import ThemeTab from './components/ThemeTab.vue';
import GroupTab from './components/GroupTab.vue';
import SeriesInfoTab from './components/SeriesInfoTab.vue';

export default {
  name: 'AiDrawManage',
  components: {
    ThemeTab,
    SeriesInfoTab,
    GroupTab,
  },
  data() {
    const tabs = [
      {
        value: 1,
        label: '主题',
      },
      {
        value: 2,
        label: '车系信息',
      },
      {
        value: 3,
        label: '主题分组',
      },
    ];
    return {
      tabs,
      tabKey: 1,
    };
  },
  methods: {
    handleAdd() {
      switch (this.tabKey) {
        case 1: {
          this.$refs.ThemeTab.handleOpen();
          break;
        }
        case 2: {
          this.$refs.SeriesInfoTab.handleOpen();
          break;
        }
        case 3: {
          this.$refs.GroupTab.handleOpen();
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
