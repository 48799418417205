import request from '@/utils/request';
import { BFZ_BFF } from '@/constant/api';

export default {
  // 获取主题列表
  // http://yapi.cloud.afanticar.net/project/2070/interface/api/49903
  getAiImageSubjectList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/pageSubject/v1`,
      method: 'post',
      data,
    });
  },
  // 获取主题操作人列表
  getAiImageSubjectOperatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/subjectActorList`,
      method: 'get',
    });
  },
  // 获取类型配置信息
  getAiImageSubjectTypeConfig(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/findComfyuiConfigByTaskType`,
      method: 'get',
      params,
    });
  },
  // 更新主题上下架状态
  updateAiImageSubjectStatus(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/updatePublishStatus`,
      method: 'put',
      data,
    });
  },
  // 获取主题分组列表
  getAiImageSubjectGroupList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/api/xhs/aiImageSubjectGroup/page`,
      method: 'get',
      params,
    });
  },
  // 更新主题分组
  updateAiImageSubjectGroup(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/api/xhs/aiImageSubjectGroup`,
      method: 'post',
      data,
    });
  },
  // 删除主题分组
  deleteAiImageSubjectGroup(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/api/xhs/aiImageSubjectGroup/${id}`,
      method: 'delete',
    });
  },
  // 分组操作人列表
  getAiImageSubjectGroupOperatorList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/api/xhs/aiImageSubjectGroup/listActor`,
      method: 'get',
      params,
    });
  },
  // 车系操作人列表
  getPromptActorList() {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt/actorList`,
      method: 'get',
    });
  },
  // 更新车系上下架状态
  updateCarSeriesStatus(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt/updatePublishStatus`,
      method: 'put',
      data,
    });
  },
  // 获取主题品牌列表
  getAiImageSubjectBrandList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/subjectPrincipalList`,
      method: 'get',
    });
  },
  // 获取类型配置信息(新)
  getAiImageSubjectTypeConfigNew(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsSdWebUiConfig/findByTaskType`,
      method: 'get',
      params,
    });
  },
};
