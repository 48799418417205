<template>
  <div>
    <div>管理车系、车身颜色、车辆信息还原参数；车身角度为通用参数，无需设置；</div>
    <a-row :gutter="[15, 10]" style="margin: 5px 0">
      <a-col :span="3">
        <a-select
          v-model="searchForm.actor"
          placeholder="操作人"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="(item, index) in creatorList" :key="index" :value="item">{{ item }}</a-select-option>
        </a-select>
      </a-col>

      <a-col :span="3">
        <a-select
          style="width: 100%"
          class="item-select"
          v-model="searchForm.principalIdList"
          placeholder="品牌"
          show-search
          option-filter-prop="children"
          @change="mBrandChange"
          allowClear
        >
          <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <a-select
          style="width: 100%"
          v-model="searchForm.carSeriesIdList"
          placeholder="车系"
          show-search
          option-filter-prop="children"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in searchSeriesList" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList">
      <!-- <div slot="state" slot-scope="text, record, index">
        <any-switch
          v-model="record.publishStatus"
          :checkedVal="true"
          :unCheckedVal="false"
          :loading="switchLoading"
          @change="(check) => handleSwitch(check, record)"
        >
        </any-switch>
      </div> -->
      <div slot="color" slot-scope="text, record">
        <template v-if="record.colorPromptSettingArr && record.colorPromptSettingArr.length">
          <a-tooltip>
            <template slot="title">
              <a-tag color="blue" v-for="(c, i) in record.colorPromptSettingArr" :key="i">
                {{ c.carColorKey }}
              </a-tag>
            </template>
            <a-tag color="blue" v-for="(c, i) in record.colorPromptSettingArr.slice(0, 2)" :key="i">
              {{ c.carColorKey }}
            </a-tag>
            <a-tag v-show="record.colorPromptSettingArr.length > 2" color="blue">
              +{{ record.colorPromptSettingArr.length - 2 }}
            </a-tag>
          </a-tooltip>
        </template>
        <template v-else> - </template>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="editCarSeriesOption(record)">编辑</a-button>
          <a-popconfirm
            placement="topLeft"
            title="确定删除该车系？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteByCarSeriesId(record.id)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <a-modal
      v-model="showBrandEdit"
      width="700px"
      title="车系"
      :confirmLoading="saveLoading"
      okText="保存"
      @cancel="handleBrandClose"
      @ok="handleBrandOk"
    >
      <a-form-model
        layout="inline"
        class="brand_edit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :rules="rules"
        ref="addBrandForm"
        :model="addForm"
      >
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            style="width: 443px"
            class="item-select"
            v-model="addForm.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            @change="getTypeList"
            allowClear
            :disabled="isEdit"
          >
            <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="carSeriesId">
          <a-select
            style="width: 443px"
            v-model="addForm.carSeriesId"
            placeholder="请选择车系"
            show-search
            option-filter-prop="children"
            allowClear
            @change="getSeriesColorList"
            :disabled="isEdit"
          >
            <a-select-option v-for="item in carSeriesIdList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系lora" prop="carLoraPrompt">
          <a-input style="width: 438px" allowClear v-model.trim="addForm.carLoraPrompt"></a-input>
        </a-form-model-item>
        <a-form-model-item label="车辆信息还原lora" prop="loraPrompt">
          <a-input style="width: 426px" allowClear v-model.trim="addForm.loraPrompt"></a-input>
        </a-form-model-item>
        <template v-if="promptLoading">
          <a-icon type="loading" style="margin: 15px 0 0 20px" />
        </template>
        <template v-if="addForm.colorList.length">
          <div style="display: flex" v-for="(val, index) in addForm.colorList" :key="index">
            <div style="width: 90%">
              <a-form-model-item :label-col="{ span: 9 }" :wrapper-col="{ span: 15 }" label="颜色Prompt" required>
                <a-input style="width: 100%" placeholder="颜色名" allowClear v-model.trim="val.carColorKey"></a-input>
              </a-form-model-item>
              <a-form-model-item label="">
                <a-input
                  style="width: 245px"
                  placeholder="颜色参数"
                  allowClear
                  v-model.trim="val.carColorPrompt"
                ></a-input>
              </a-form-model-item>
            </div>
            <div>
              <a-button
                type="link"
                v-show="addForm.colorList.length != 1"
                shape="circle"
                icon="minus"
                @click="minusColor(index)"
              ></a-button>
              <a-button
                type="link"
                v-show="index + 1 == addForm.colorList.length"
                shape="circle"
                icon="plus"
                @click="addColor"
              />
            </div>
          </div>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import AnySwitch from '@/components/AnySwitch/AnySwitch.vue';
import _api from '../api';
import api from '@/api/xhsAgencyApi';

export default {
  components: {
    AnySwitch,
  },
  data() {
    const stateList = [
      { label: '已下架', value: 0 },
      { label: '已上架', value: 1 },
    ];
    return {
      rules: {
        principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        carSeriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
        carLoraPrompt: [{ required: true, message: '请输入参数', trigger: 'change' }],
        loraPrompt: [{ required: true, message: '请输入参数', trigger: 'change' }],
      },
      columns: [
        // {
        //   title: '上架',
        //   width: 100,
        //   scopedSlots: { customRender: 'state' },
        // },
        {
          align: 'center',
          title: '品牌',
          dataIndex: 'principalName',
        },
        {
          align: 'center',
          title: '车系',
          dataIndex: 'carSeriesName',
        },
        {
          align: 'center',
          title: '车系Lora',
          dataIndex: 'carLoraPrompt',
          ellipsis: true,
        },
        {
          align: 'center',
          title: '颜色',
          scopedSlots: { customRender: 'color' },
        },
        {
          align: 'center',
          width: 200,
          title: '车辆信息还原',
          dataIndex: 'loraPrompt',
          ellipsis: true,
        },
        {
          align: 'center',
          title: '操作人',
          customRender(record) {
            return record.actor || '-';
          },
        },
        {
          align: 'center',
          title: '更新时间',
          dataIndex: 'mtime',
          sorter: true,
        },
        {
          align: 'center',
          title: '操作',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      stateList,
      creatorList: [],
      searchForm: {
        creator: undefined,
        name: undefined,
      },
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      sortList: [],
      showBrandEdit: false,
      saveLoading: false,
      brandList: [],
      carSeriesIdList: [],
      promptLoading: false,
      addForm: {
        principalId: undefined,
        carSeriesId: undefined,
        carLoraPrompt: undefined,
        loraPrompt: undefined,
        colorList: [
          {
            carColorKey: undefined,
            carColorPrompt: undefined,
          },
        ],
      },
      isEdit: false,
      switchLoading: false,
      searchSeriesList: [],
    };
  },
  created() {
    this.getDataList(true);
    this.getBrandList();
    this.getCreatorList();
  },
  methods: {
    async getCreatorList() {
      const { data, code, message } = await _api.getPromptActorList();
      if (code === 200) {
        this.creatorList = data;
      } else {
        this.$message.error(message);
      }
    },
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const params = {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      api
        .getXhsAiImageCarSeriesPromptList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.dataList = data.list;
            this.pagination.total = data.total;
            console.log(data);
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async deleteByCarSeriesId(id) {
      const { code } = await api.deleteByCarSeriesId({ id });
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current--;
        }
        this.getDataList();
      } else {
        this.$message.error('删除失败');
      }
    },
    onTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? [
            {
              fieldName: sorter.columnKey,
              orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            },
          ]
        : [];
      this.sortList = sort;
      this.getDataList(true);
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    async handleSwitch(check, { id }) {
      if (!check) {
        const _this = this;
        this.$confirm({
          title: `是否确认${check ? '上架' : '下架'}该车系?`,
          content: (h) => <div style="color:red;"></div>,
          async onOk() {
            _this.switchLoading = true;
            const params = {
              id,
              publishStatus: check ? 1 : 0,
            };
            const { code, message } = await _api
              .updateCarSeriesStatus(params)
              .finally(() => (_this.switchLoading = false));
            if (code === 200) {
              if (!isNaN(_this.searchForm.publishStatus)) _this.getDataList();
              _this.$message.success('操作成功');
            } else {
              _this.$message.error(message);
              _this.getDataList();
            }
          },
          onCancel() {
            _this.dataList.find((item) => item.id === id).publishStatus = !check;
            _this.$message.info('已取消');
          },
          class: 'test',
        });
      } else {
        this.switchLoading = true;
        const params = {
          id,
          publishStatus: check ? 1 : 0,
        };
        const { code, message } = await _api.updateCarSeriesStatus(params).finally(() => (this.switchLoading = false));
        if (code === 200) {
          // if (!isNaN(this.searchForm.publishStatus)) this.getDataList();
          this.$message.success('操作成功');
        } else {
          this.$message.error(message);
          this.getDataList();
        }
      }
    },
    mBrandChange(value) {
      this.searchForm.carSeriesIdList = undefined;
      this.searchSeriesList = [];
      if (value) {
        api.getNewTypeList({ principalIds: value }).then((res) => {
          if (res.code === 200) {
            this.searchSeriesList = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      }
      this.getDataList(true);
    },
    // -----------------------------------------新增/编辑相关
    // 添加车系
    handleOpen() {
      this.showBrandEdit = true;
    },
    // 编辑车系
    async editCarSeriesOption(item) {
      this.isEdit = true;
      this.showBrandEdit = true;
      this.addForm.principalId = item.principalId;
      this.carSeriesIdList = [];
      this.addForm.principalId &&
        api.getNewTypeList({ principalIds: this.addForm.principalId }).then((res) => {
          if (res.code === 200) {
            this.carSeriesIdList = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      this.addForm.carSeriesId = item.carSeriesId;
      this.addForm.carSeriesId && this.getSeriesColorList(this.addForm.carSeriesId);
    },
    handleBrandClose() {
      this.$refs.addBrandForm && this.$refs.addBrandForm.clearValidate();
      this.addForm = {
        id: undefined,
        carSeriesId: undefined,
        carLoraPrompt: undefined,
        principalId: undefined,
        loraPrompt: undefined,
        colorList: [{ carColorKey: undefined, carColorPrompt: undefined }],
      };
      this.carSeriesIdList = [];
      this.showBrandEdit = false;
      this.isEdit = false;
    },
    handleBrandOk() {
      this.$refs.addBrandForm.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          const params = {
            id: this.addForm.id || undefined,
            carSeriesId: this.addForm.carSeriesId,
            carLoraPrompt: this.addForm.carLoraPrompt,
            loraPrompt: this.addForm.loraPrompt,
            carSeriesName: this.carSeriesIdList.find((item) => item.id === this.addForm.carSeriesId)?.name,
            principalId: this.addForm.principalId,
            principalName: this.brandList.find((item) => item.id === this.addForm.principalId)?.principalName,
            colorPromptSettingArr: this.addForm.colorList.filter((val) => val.carColorKey && val.carColorPrompt),
          };
          if (!params.colorPromptSettingArr.length) return this.$message.info('至少添加一个颜色');
          const { code, message } = await api
            .addXhsAiImageCarSeriesPrompt(params)
            .finally(() => (this.saveLoading = false));
          if (code === 200) {
            this.$message.success('操作成功');
            this.handleBrandClose();
            this.getDataList(true);
          } else {
            this.$message.error(message);
          }
        }
      });
    },
    getTypeList(value) {
      this.addForm = {
        id: undefined,
        carSeriesId: undefined,
        carLoraPrompt: undefined,
        loraPrompt: undefined,
        principalId: value,
        colorList: [{ carColorKey: undefined, carColorPrompt: undefined }],
      };
      if (!value) return (this.carSeriesIdList = []);
      return new Promise((resolve) => {
        api.getNewTypeList({ principalIds: this.addForm.principalId }).then((res) => {
          if (res.code === 200) {
            this.carSeriesIdList = res.data;
            resolve(true);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    async getSeriesColorList(carSeriesId) {
      this.$refs.addBrandForm && this.$refs.addBrandForm.clearValidate();
      this.addForm.id = undefined;
      this.addForm.carLoraPrompt = undefined;
      this.addForm.loraPrompt = undefined;
      this.addForm.colorList = [{ carColorKey: undefined, carColorPrompt: undefined }];
      if (carSeriesId) {
        this.promptLoading = true;
        const { code, data } = await api.findByCarSeriesId({ carSeriesId }).finally(() => (this.promptLoading = false));
        if (code === 200 && Object.keys(data).length) {
          this.addForm.carLoraPrompt = data.carLoraPrompt;
          this.addForm.loraPrompt = data.loraPrompt;
          this.addForm.id = data.id;
          this.addForm.colorList =
            data.colorPromptSettingArr && data.colorPromptSettingArr.length
              ? data.colorPromptSettingArr
              : [{ carColorKey: undefined, carColorPrompt: undefined }];
        }
      }
    },
    addColor() {
      this.addForm.colorList.push({ carColorKey: undefined, carColorPrompt: undefined });
    },
    minusColor(index) {
      this.addForm.colorList.splice(index, 1);
    },
    // 获取品牌
    async getBrandList() {
      api.getBrandList().then(({ data, code, message }) => {
        if (code === 200) {
          this.brandList = data;
        } else {
          this.$message.error(message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
